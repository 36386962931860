import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import GitHubIcon from "../components/github-icon";

import styled from "@emotion/styled";

const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
`;

const GITHUB_SIZE = 24;
const ImageWrapper = styled.div`
  width: ${GITHUB_SIZE}px;
  height: ${GITHUB_SIZE}px;
  margin-left: 10px;
`;

const AutoLink = ({ to, children, ...props }) => {
  if (!to.startsWith("https://github.com/")) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }
  return (
    <LinkWrapper href={to} {...props}>
      {children}
      <ImageWrapper>
        <GitHubIcon />
      </ImageWrapper>
    </LinkWrapper>
  );
};

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <h1>Home</h1>
      <ul>
        <li>
          <AutoLink to="/mobile-typing-speed-test">
            Mobile Typing Speed Test
          </AutoLink>
        </li>
        <li>
          <AutoLink to="/arm-mac">
            ARM Mac: Why I'm Worried About Virtualization
          </AutoLink>
        </li>
        <li>
          <AutoLink to="/react-use-date">react-use-date</AutoLink>
        </li>
        <li>
          <AutoLink to="https://github.com/bmalehorn/vscode-fish/">
            VSCode Fish
          </AutoLink>
        </li>
        <li>
          <AutoLink to="https://github.com/bmalehorn/vscode-vimspired">
            Vimspired
          </AutoLink>
        </li>
        <li>
          <AutoLink to="https://github.com/bmalehorn/vscode-scroll-viewport">
            VSCode Scroll Viewport
          </AutoLink>
        </li>
        <li>
          <AutoLink to="https://github.com/bmalehorn/vscode-test-switcher">
            VSCode Test Switcher
          </AutoLink>
        </li>
        <li>
          <AutoLink to="/back-is-forward">Back is Forward</AutoLink>
        </li>
        <li>
          <AutoLink to="/up-is-down#top">Up is Down</AutoLink>
        </li>
        <li>
          <AutoLink to="/runaway">Runaway</AutoLink>
        </li>
        <li>
          <AutoLink to="/highlight">Highlight</AutoLink>
        </li>
        <li>
          <AutoLink to="/stowaway">Stowaway</AutoLink>
        </li>
        <li>
          <AutoLink to="/tiny-link">Tiny Link</AutoLink>
        </li>
        <li>
          <AutoLink to="/css-grid">CSS Grid</AutoLink>
        </li>
        <li>
          <AutoLink to="/auto-clicker">Auto Clicker</AutoLink>
        </li>
      </ul>
    </Layout>
  );
};

export default IndexPage;
